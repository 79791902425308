<template>
    <v-layout>
        <v-app-bar color="blue" density="compact">
            <v-container class="pa-0 header-container">
                <div class="ec-parcel-text text-h6 font-weight-bold">ECPARCEL</div>
            </v-container>
        </v-app-bar>
        <v-main>
            <v-container>
                <router-view />
            </v-container>
        </v-main>
    </v-layout>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'DefaultLayout',
    data() {
        return {};
    }
};
</script>

<style scoped>
.v-toolbar {
    flex: 0 1 auto;
}
.header-container {
    padding-left: 16px !important;
    margin-left: 0 !important;
}
.ec-parcel-text {
    color: white;
    letter-spacing: 1px;
    font-size: 1.25rem;
}
</style>
