<template>
    <v-row>
        <v-col :cols="9">
            <alert-message ref="errorAlert" :message="data.errorAlertMessage"></alert-message>
            <v-sheet>
                <ShipmentTracking :shipment-tracking="data.shipmentTracking"></ShipmentTracking>
            </v-sheet>
        </v-col>
        <v-col :cols="3">
            <v-sheet>
                <v-btn class="mt-2" variant="text" @click="onTrackClick"> New Track </v-btn>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import ShipmentTracking from '../components/ShipmentTracking.vue';
import * as _ from 'lodash';
import { PathDefinition } from '../utils/PathDefinition';
import AlertMessage from '../components/AlertMessage.vue';

export default defineComponent({
    name: 'Track',

    components: {
        ShipmentTracking,
        AlertMessage
    },

    setup() {
        const data = reactive({
            errorAlertMessage: '',
            trackingNumber: '',
            shipmentTrackingDefault: {},
            shipmentTracking: {
                shipmentTrackingNumber: '',
                trackingEventList: []
            }
        });
        return {
            data
        };
    },
    async created() {
        this.data.shipmentTrackingDefault = _.cloneDeep(this.data.shipmentTracking);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const params = urlParams.get('trackingNumber');
        const queryValue = _.trim(params);
        if (queryValue) {
            this.data.trackingNumber = queryValue;
            await this.search();
        }
    },
    methods: {
        async onTrackClick() {
            await this.$router.push({ path: PathDefinition.home });
        },
        async search() {
            this.fillInSearchValue(this.data.trackingNumber);
            const searchKey = _.trim(this.data.trackingNumber);
            if (!searchKey) {
                return;
            }
            const response = await this.getShipmentTracking(searchKey);
            await this.handleResponseResult(response);
        },
        async getShipmentTracking(trackingNumber: string) {
            const response = await fetch(`/api/shipmentTracking/getShipmentTracking?trackingNumber=${trackingNumber}`);
            return response;
        },
        async handleResponseResult(response: any) {
            const json = await response.json();
            // console.log('json', json);
            if (json.success) {
                this.data.shipmentTracking = json.result;
            } else {
                this.data.shipmentTracking = _.cloneDeep(this.data.shipmentTrackingDefault);
                this.data.errorAlertMessage = this.formatApiResponseError(json.errors);
                this.$refs.errorAlert.showAlert();
            }
            this.fillInSearchValue(this.data.trackingNumber);
        },
        fillInSearchValue(searchValue: string) {
            this.data.shipmentTracking.shipmentTrackingNumber = searchValue;
        },
        formatApiResponseError(errors: object[]) {
            return _.map(errors, (err) => `${_.get(err, 'code')}: ${_.get(err, 'message')}`).join(',');
        }
    }
});
</script>
