<template>
    <component :is="layout" />
</template>

<script lang="ts">
import BlankLayout from './layouts/BlankLayout.vue';
import DefaultLayout from './layouts/DefaultLayout.vue';

export default {
    name: 'App',
    components: {
        BlankLayout,
        DefaultLayout
    },
    data() {
        return {
            layout: null
        };
    },
    watch: {
        $route(to) {
            if (to.meta.layout !== undefined) {
                this.layout = to.meta.layout;
            } else {
                this.layout = 'BlankLayout';
            }
        }
    }
};
</script>
