<template>
    <v-card>
        <v-card-title style="text-align: center">
            <h2>Tracking Number: {{ shipmentTracking.shipmentTrackingNumber }}</h2>
        </v-card-title>
        <v-card-title>
            <h3 style="color: darkgray">Order Status</h3>
            <h2 style="padding-left: 50px">
                {{ shipmentTracking.trackingEventList[0]?.eventMessage }}
            </h2>
        </v-card-title>
        <v-divider :color="`red`" :thickness="5"></v-divider>
        <v-timeline side="end">
            <v-timeline-item
                v-for="(point, idx) in shipmentTracking.trackingEventList"
                :key="point.eventTime + point.eventMessage"
                size="small"
                :dot-color="idx === 0 ? `red` : `grey`"
            >
                <template v-slot:opposite>
                    <div>{{ formatTime(point.eventTime) }}</div>
                </template>
                <h2>{{ point.eventMessage }}</h2>
                <p>{{ point.city }} {{ point.state }}</p>
            </v-timeline-item>
        </v-timeline>
    </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default defineComponent({
    name: 'ShipmentTracking',
    props: ['shipmentTracking'],
    setup(props) {
        return props;
    },
    methods: {
        formatTime: function (iso8601Time: string) {
            const dt = dayjs(iso8601Time);
            return dt.format('MMM DD hh:mm a');
        }
    }
});
</script>
