<template>
    <v-row>
        <v-col class="ml-md-auto pt-16">
            <v-sheet class="mx-auto" :max-width="$vuetify.display.smAndDown ? '90%' : '70%'">
                <v-img
                    class="ecparcel-banner-logo mx-auto"
                    :src="'/ec-parcel-logo-v2.png'"
                    :max-width="$vuetify.display.smAndDown ? '250' : '400'"
                    contain
                />
            </v-sheet>
            <p class="pt-5 pb-5 introduction w-75 mx-auto">
                ecParcel is a rapidly growing logistics company with extensive experience base, and a commitment to
                providing quality service and customer experience.
            </p>
            <p class="pt-5 pb-5 introduction w-75 mx-auto">
                If you have any problems about your shipment, please contact: inquiry@ec-parcel.com
            </p>
            <p></p>
            <v-sheet class="w-75 mx-auto">
                <v-text-field
                    ref="trackingNumber"
                    v-model="trackingNumber"
                    :label="'Tracking Number'"
                    @keydown="onSearchPress"
                ></v-text-field>
            </v-sheet>
            <v-sheet class="text-center">
                <v-btn class="mt-2 justify-center" variant="flat" color="grey" @click="onSearchClick"> Search </v-btn>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { PathDefinition } from '../utils/PathDefinition';
import * as _ from 'lodash';

export default defineComponent({
    name: 'Home',

    data() {
        return {
            trackingNumber: ''
        };
    },
    mounted() {
        this.$refs.trackingNumber.focus();
    },
    methods: {
        onSearchClick(evt: any) {
            this.searchWithTrackingNumber();
        },
        onSearchPress(evt: any) {
            if (evt.key === 'Enter') {
                this.searchWithTrackingNumber();
            }
        },
        searchWithTrackingNumber(evt: any) {
            const searchKey = _.trim(this.trackingNumber);
            if (searchKey) {
                window.location.href = `${PathDefinition.track}?trackingNumber=${searchKey}`;
            }
        }
    }
});
</script>

<style>
.introduction {
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center;
}
.ecparcel-banner-logo {
    width: 100%;
    height: auto;
}
</style>
