import { createRouter, createWebHistory } from 'vue-router';
import Track from './pages/Track.vue';
import Home from './pages/Home.vue';
import { PathDefinition } from './utils/PathDefinition';

const routes = [
    {
        path: PathDefinition.home,
        name: 'Home',
        component: Home,
        meta: { layout: 'DefaultLayout' }
    },
    {
        path: PathDefinition.track,
        name: 'Track',
        component: Track,
        meta: { layout: 'DefaultLayout' }
    }
];

const router = createRouter({ history: createWebHistory(), routes });
export default router;
